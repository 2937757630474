<template>
    <div class="organization_register_confirm">
        <div class="organization_register_confirm__top">
            <p class="organization_register_confirm__top-title">
                招待内容の確認
            </p>
        </div>
        <div class="organization_register_confirm__body" v-if="!state.preloadMain">
            <div class="mw-1000">
                <div class="organization_register_confirm__body-message">
                    内容をご確認ください。
                </div>
                <div class="organization_register_confirm__body__form">
                    <div class="horizontal">
                        <div class="form-ctrl">
                            <label  class="text">企業名</label>
                            <div
                                class="organization_register_confirm__body__form-confirm"
                            >
                                {{state.organization.name}}
                            </div>
                        </div>
                        <div class="form-ctrl">
                            <label  class="text">（企業名）カナ</label>
                            <div
                                class="organization_register_confirm__body__form-confirm"
                            >
                                {{state.organization.name_kana}}
                            </div>
                        </div>
                        <div class="btn_group">
                            <button
                                type="button"
                                class="btn-md btn-light-gray shadow"
                                @click="rejectInviteOrganization"
                            >
                                辞退する
                            </button>
                            <button
                                type="button"
                                :disabled="isSubmitting"
                                :class="{ submitting: isSubmitting }"
                                class="btn-md btn-blue shadow"
                                @click="acceptInviteOrganization"
                            >
                            <i
                                v-if="isSubmitting"
                                class="fas fa-circle-notch fa-spin"
                            ></i>
                                承諾する
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Alert v-if="state.showAlertPopup" @close="state.showAlertPopup = false" :content="state.popupContent" :title="state.popupTitle"/>
</template>

<script
    lang="ts"
    src="@/presentation/views/organization/register-invited/composition-confirm/CompositionConfirm.ts"
/>


<style lang="scss" scoped>
@import "@/presentation/views/organization/register-invited/composition-confirm/CompositionConfirm.scss";
</style>
