import { reactive, defineComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import routePath from "@/commons/RoutePath";
import { useForm } from "vee-validate";
import OrganizationRegisterInteractor from "@/domain/usecases/OrganizationRegisterInteractor";
import DIContainer from "@/core/DIContainer";
import LanguageUtil from "@/commons/LanguageUtil";
import RoutePath from "@/commons/RoutePath";
import Alert from "@/presentation/components/modals/alert/Alert.vue";
import Preloaders from '@/presentation/components/preloader/Preloaders.vue';
import { useStore } from "vuex";
export default defineComponent({
    name: "OrganizationRegisterInvitedCompositionConfirm",
    components: {
        Alert: Alert,
        Preloaders: Preloaders
    },
    setup: function () {
        var store = useStore();
        var organizationRegisterInteractor = DIContainer.instance.get(OrganizationRegisterInteractor);
        var router = useRouter();
        var route = useRoute();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            organization: {},
            preloadMain: true,
            showAlertPopup: false,
            popupTitle: '',
            popupContent: ''
        });
        var register_token = route.query.register_token;
        var organization_member_id = Number(route.query.organization_member_id);
        var organization_id = Number(route.query.organization_id);
        var setValues = useForm({
            initialValues: {
                password: null,
                password_confirmation: null,
            }
        }).setValues;
        var isSubmitting = useForm().isSubmitting;
        function acceptInviteOrganization() {
            isSubmitting.value = true;
            organizationRegisterInteractor
                .acceptInviteComposition(organization_member_id, organization_id)
                .then(function (result) {
                state.popupTitle = "招待の承諾";
                state.popupContent = "招待を承諾しました。";
                state.showAlertPopup = true;
                setTimeout(function () {
                    router.push(RoutePath.SELECT_ORGANIZATION);
                }, 2000);
            })
                .catch(function (result) {
                setTimeout(function () {
                    state.popupTitle = "システムエラー";
                    state.popupContent = "招待拒否でエラーが発生しました。";
                    state.showAlertPopup = true;
                    router.push(routePath.TOP);
                }, 2000);
            })
                .finally(function () {
                isSubmitting.value = false;
            });
        }
        function rejectInviteOrganization() {
            organizationRegisterInteractor
                .rejectInviteComposition(organization_member_id, organization_id)
                .then(function (result) {
                state.popupTitle = "招待の拒否";
                state.popupContent = "招待を拒否しました。";
                state.showAlertPopup = true;
                setTimeout(function () {
                    router.push(RoutePath.TOP);
                }, 2000);
            })
                .catch(function (result) {
                state.popupTitle = "システムエラー";
                state.popupContent = "招待拒否でエラーが発生しました。";
                state.showAlertPopup = true;
                setTimeout(function () {
                    router.push(RoutePath.TOP);
                }, 2000);
            });
        }
        onMounted(function () {
            organizationRegisterInteractor.validateInviteComposition(register_token, organization_member_id, organization_id).then(function (res) {
                if (res.data.is_available == false) {
                    router.push({ path: routePath.ORGANIZATION_REGISTER_INVITED, query: { organization_id: organization_id,
                            organization_member_id: organization_member_id,
                            register_token: register_token
                        }
                    });
                }
                state.preloadMain = false;
                state.organization = res.data;
            }, function (error) {
                state.preloadMain = false;
                if (Object.keys(error.validation_errors).length) {
                    router.push(RoutePath.ORGANIZATION_REGISTER_INVITED_INVALID_URL);
                }
                else {
                    router.push(RoutePath.ORGANIZATION_REGISTER_INVITED_EXPIRED);
                }
                return;
            });
            var organizationInvitedResgisterData = store.state.organizationInvitedResgisterData;
            if (organizationInvitedResgisterData) {
                setValues({
                    password: organizationInvitedResgisterData.password,
                    password_confirmation: organizationInvitedResgisterData.password_confirmation
                });
            }
        });
        return {
            state: state,
            routePath: routePath,
            isSubmitting: isSubmitting,
            acceptInviteOrganization: acceptInviteOrganization,
            rejectInviteOrganization: rejectInviteOrganization
        };
    }
});
